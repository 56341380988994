<template>
  <div class="flex flex-col space-y-1 px-4">
    <ion-searchbar :debounce="debounce" :placeholder="placeholder" mode="ios" color="light"
      @ionChange="handleChange($event)"></ion-searchbar>
    <slot />
  </div>
</template>

<script>
import { IonSearchbar } from "@ionic/vue";
export default {
  name: "Search",
  props: {
    placeholder: {
      type: String,
      default: "Search",
    },
    searches: {
      type: Array,
      required: true,
    },
    debounce: {
      type: Number,
      default: 200,
    },
    onChange: {
      type: Function,
      default: null,
    },
    limit: {
      type: Number,
      default: 6,
    }
  },
  data() {
    return {
      results: [],
    };
  },
  components: { IonSearchbar },
  emits: ["change","Loading"],
  methods: {
    async handleChange(e) {
      this.$emit("loading");
      for (var i = 0; i < this.searches.length; i++) {
        const {
          API,
          model,
          paths,
          itemsPath,
          valuePath,
          includeQuery,
          category,
          isCaseSensitive,
          populate,
          limit,
          onChange,
        } = this.searches[i];
        let data;
        if (!!onChange) {
          data = await onChange(e.detail.value);
        } else {
          data = e.detail.value
            ? await API.query(model, {
              populate: populate && {
                paths: populate
              },
              paginate: {
                limit: limit || this.limit
              },
              search: {
                value: e.detail.value,
                paths,
                isCaseSensitive,
              }
            })
            : [];
        }

        if (itemsPath) data = data[itemsPath] || [];
        if (valuePath) data = data.map((_) => _[valuePath]) || [];
        if (includeQuery) data = [e.detail.value, ...data];
        this.results[category] = data;
      }

      this.$emit("change", this.results);
    },
  },
};
</script>