<template>
    <PageLayout title="Search" linkDefault1="/">
        <Search @loading="loadingg" @change="setResults($event)" v-bind:searches="currentSearch" :debounce="800">
            <div class="py-2 flex">
                <FilterTag class="m-1 capitalize" @click="(setCategory())" :active="currentCategory == 'all'">all
                </FilterTag>
                <FilterTag v-for="(category, i) in categories" :key="i" @click="setCategory(category)"
                    :active="currentCategory == category" class="m-1 capitalize">{{
                            category
                    }}</FilterTag>
            </div>
        </Search>
        <div>
        <Loading v-if="loading" class="-mt-48" />
        <div v-else :key="i" class="flex flex-col space-y-6" v-for="(category, i) in categories">
            <div :key="update" v-if="currentCategory == 'all' || category == currentCategory">
                <h2 v-if="currentCategory == 'all' && results[category]?.length > 0"
                    class="capitalize font-bold font-poppins px-4 py-1">{{ category }}</h2>
                <div class="my-1 px-4" v-if="category == 'tutors'">
                    <Avatar @click="this.$router.push(`/tutor/${user.username}`)" class="my-4" v-for="({ user }, i) in results.tutors" :key="i" :user="user" name username tutor1 showCap />
                </div>
                <div class="pt-5 pb-6 flex shrink-0 overflow-scroll scrollbar-hide"
                    :class="{ 'flex-col items-center space-y-4': currentCategory == 'classes', 'pl-4 space-x-3': currentCategory != 'classes' }"
                    v-if="category == 'classes'">
                    <ClassCard v-for="(_class, i) in results.classes" :key="i" :_class="_class" />
                </div>
                <div class="my-1" v-if="category == 'posts'">
                    <Post v-for="(post, i) in results.posts" :key="i" :post="post" />
                </div>
            </div>
        </div>
        </div>
    </PageLayout>
</template>
  
<script>
import Search from "../../components/Feed/Search.vue";
import FilterTag from "../../components/Feed/FilterTag.vue";

import FeedsAPI from "../../../FeedsAPI";
import tutoruuAPI from "../../../tutoruuAPI";
import Avatar from "../../components/Feed/Avatar.vue";
import ClassCard from "../../components/Feed/ClassCard.vue";
import Post from "../../components/Feed/Post.vue";
import PageLayout from "../../components/base/pageLayout.vue";
import Loading from "../../components/Loading.vue";
import { searchUsers, GetPostsBySearch } from '../../helpers/API/Feeds'

export default {
    name: "Tags Search",
    components: {
        Search,
        FilterTag,
        Avatar,
        ClassCard,
        Post,
        PageLayout,
        Loading
    },
    mounted() {
        this.setCategory(this.$route.query.tab);
    },
    data() {
        return {
            categories: ["classes", "tutors", "posts"],
            results: {},
            update: 0,
            loading:false,
            currentSearch: [],
            currentCategory: "all",
            searches: [
                {
                    API: tutoruuAPI,
                    model: 'tutor/course-search',
                    category: 'tutors',
                },
                {
                    API: tutoruuAPI,
                    paths: ['name', 'desc'],
                    itemsPath: 'classes',
                    model: 'class',
                    category: 'classes',
                    populate: 'university',
                },
                {
                    API: FeedsAPI,
                    category: "posts",
                    onChange: async (value) => {
                        this.loading=true;
                        const { data } = await GetPostsBySearch(value);
                        return data.data;
                    }
                },
            ]
        };
    },
    methods: {
        setResults(res) {
            this.loading=true
            this.results.posts = res.posts
            this.results.tutors = res.tutors
            this.results.classes = res.classes
            this.update++;
            this.loading=false;
        },
        setCategory(category = "all") {
            this.loading=true;
            if (category === "all") this.currentSearch = this.searches;
            else this.currentSearch = this.searches.filter(search => search.category === category);

            this.currentCategory = category;

            this.update++;
            this.loading=false;
        },
        loadingg(){
            this.loading=true;
        }
    },
};
</script>